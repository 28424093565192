import { Resource, Unit } from "@maxxton/cms-mxts-api";
import { getSelectedAccoTypeReservedResource, getUnitId } from "../../../redux/reducers/myEnv/myEnv.util";

import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { DomainObjectUtil } from "../../../utils/domainobject.util";
import { MyEnvReservation } from "../../../redux/reducers/myEnv/myEnvState";
import { getMxtsEnv } from "../../mxts";

export async function getUnitOrAccoTypeFromReservation(context: CMSProviderProperties, esReservation: MyEnvReservation | undefined): Promise<{ unit?: Unit; accoType?: Resource }> {
    const env = await getMxtsEnv(context);
    const myEnvUnitId = getUnitId(esReservation);
    const selectedAccoTypeReservedResource = getSelectedAccoTypeReservedResource(esReservation);
    const { mxtsApi } = context;
    if (myEnvUnitId) {
        const unit = await DomainObjectUtil.getUnitById(mxtsApi, myEnvUnitId, env);
        return { unit: unit || undefined };
    }
    const accoType = await DomainObjectUtil.getResourceById(mxtsApi, selectedAccoTypeReservedResource?.resourceId, env);
    return { accoType: accoType || undefined };
}
