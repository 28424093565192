import * as Icons from "../plugins/page/icons";

import { GroupOptions, InputSpecGroup } from "../form-specs/index";

import { IconColoring } from "../form-specs";
import { getI18nLocaleObject } from "../i18n";
import namespaceList from "../i18n/namespaceList";

export interface IconConfigurationOptions {
    showIcons: boolean;
    iconProperties: string;
    iconColor: IconColoring;
    iconPosition: IconPosition;
    iconRight: boolean;
}

// Types
export type IconPosition = "" | "top" | "center" | "bottom";

export const iconList = Object.keys(Icons.default).map((key: any) => ({ label: (Icons.default as any)[key], value: key }));

// Labels
export const I18N_SHOW_ICON = getI18nLocaleObject(namespaceList.widgetWebContent, "iconTitle");
export const I18N_ICON_RIGHT = getI18nLocaleObject(namespaceList.widgetWebContent, "iconToRight");
export const I18N_ICON_PROPERTIES = getI18nLocaleObject(namespaceList.widgetWebContent, "iconProperty");
export const I18N_ICON_COLOR = getI18nLocaleObject(namespaceList.widgetWebContent, "iconColor");

/* eslint-disable max-lines-per-function */
export const IconConfigurationSpec = <E, P extends keyof E>(options: GroupOptions<E, P>): InputSpecGroup<E, P, IconConfigurationOptions, keyof IconConfigurationOptions> => {
    const { variable, visible } = options;
    return {
        variable,
        visible,
        type: "group",
        specs: [
            {
                variable: "showIcons",
                label: I18N_SHOW_ICON,
                type: "checkbox",
            },
            {
                variable: "iconRight",
                label: I18N_ICON_RIGHT,
                type: "checkbox",
                visible: (options: IconConfigurationOptions) => options?.showIcons,
            },
            {
                variable: "iconColor",
                label: I18N_ICON_COLOR,
                type: "dual-color",
                default: "color-brand-alt" as IconColoring,
                visible: (options: IconConfigurationOptions) => options?.showIcons,
            },
            {
                variable: "iconProperties",
                label: I18N_ICON_PROPERTIES,
                type: "select",
                visible: (options: IconConfigurationOptions) => options?.showIcons,
                optionList: iconList,
            },
            {
                variable: "iconProperties",
                type: "icons",
                visible: (options: IconConfigurationOptions) => options?.showIcons,
            },
        ],
    };
};

export const defaultIconConfigurationValues: IconConfigurationOptions = {
    showIcons: false,
    iconProperties: "",
    iconColor: "color-brand",
    iconPosition: "top",
    iconRight: false,
};
