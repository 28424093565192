import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { IconConfigurationOptions } from "../utils/icon.util";

export interface IconProps {
    iconConfiguration: IconConfigurationOptions;
}

export const Icon: React.FC<IconProps> = (props: IconProps): JSX.Element | null => {
    const { iconConfiguration } = props;
    if (iconConfiguration?.showIcons && iconConfiguration.iconProperties) {
        return (
            <FontAwesome
                name={iconConfiguration.iconProperties}
                className={classNames("icon", {
                    [`color-${iconConfiguration.iconColor}`]: iconConfiguration.iconColor?.includes("theme"),
                    ["ml-2"]: iconConfiguration.iconRight,
                    ["mr-2"]: !iconConfiguration.iconRight,
                })}
                style={{ color: iconConfiguration.iconColor && iconConfiguration.iconColor?.includes("rgba") ? iconConfiguration.iconColor : "" }}
            />
        );
    }
    return null;
};
