import * as React from "react";

import { AccommodationType, Unit } from "../../../mxts";
import { Resource as MxtsResource, Unit as MxtsUnit } from "@maxxton/cms-mxts-api";
import { backgroundColorPicker, fontColorPicker } from "../../../../utils/colorpicker.util";

import { CMSProvidedProperties } from "../../../../containers/cmsProvider.types";
import { DynamicWidgetBaseProps } from "../../../dynamic/dynamicWidget.types";
import { Icon } from "../../../../components/Icon";
import { ReservationContainerCRPProps } from "../../../dynamic/reservation/container/reservationContainer.types";
import { State } from "../../../../redux";
import { WidgetOptions } from "./";
import { getAccoTypeReservedResources } from "../../../../redux/reducers/myEnv/myEnv.util";
import { getI18nLocaleString } from "../../../../i18n";
import { getUnitOrAccoTypeFromReservation } from "../bedroomBathroom.util";
import namespaceList from "../../../../i18n/namespaceList";
import { setOpacityOnHide } from "../../../../components/utils";
import { useSelector } from "react-redux";

interface BedroomWidgetProps extends DynamicWidgetBaseProps<WidgetOptions>, ReservationContainerCRPProps {
    options: WidgetOptions;
    context: CMSProvidedProperties;
    accommodationType?: AccommodationType;
    unit?: Unit;
}

export const Bedroom = (props: BedroomWidgetProps) => {
    const { context, options, isMyEnvWidget, esReservationResult } = props;
    const { currentLocale, site } = context;
    const { bedroomFontColor, nameStyle, bedroomTextBackground, borderLineColor } = options;
    const myEnvState = useSelector((state: State) => state.myEnvState);
    const [myEnvUnit, setMyEnvUnit] = React.useState<MxtsUnit>();
    const [myEnvAccoType, setMyEnvAccoType] = React.useState<MxtsResource>();
    const esReservation = isMyEnvWidget ? esReservationResult || myEnvState?.selectedReservation || undefined : undefined;
    React.useEffect(() => {
        getUnitOrAccoTypeFromReservation(context, esReservation).then(({ unit, accoType }) => {
            setMyEnvUnit(unit);
            setMyEnvAccoType(accoType);
        });
    }, [getAccoTypeReservedResources(esReservation)]);
    const accoTypeNrOfBedrooms = myEnvAccoType?.nrOfBedrooms || props.accommodationType?.nrOfBedrooms;
    const unitTypeNrOfBedrooms = myEnvUnit?.nrOfBedrooms || props.unit?.nrOfBedrooms;

    const hideWidget = setOpacityOnHide(options);

    return accoTypeNrOfBedrooms || unitTypeNrOfBedrooms ? (
        <div className={`display-bedroom ${options.bedroomFontSize ? options.bedroomFontSize : ""}`}>
            <div
                // eslint-disable-next-line max-len
                className={`${options.nameStyle ? options.nameStyle : ""} ${hideWidget} border-bottom-${options.borderWidth ? options.borderWidth : "none"}
                ${backgroundColorPicker(nameStyle, bedroomTextBackground, borderLineColor)}`}
                style={{
                    background:
                        options.nameStyle === "background-text" && options.bedroomTextBackground && options.bedroomTextBackground.indexOf("rgba") > -1 ? options.bedroomTextBackground : undefined,
                    borderColor: options.nameStyle === "underline-text" && options.borderLineColor && options.borderLineColor.indexOf("rgba") > -1 ? options.borderLineColor : undefined,
                }}
            >
                {!options.iconConfiguration?.iconRight && <Icon iconConfiguration={options.iconConfiguration} />}

                {
                    <span
                        // eslint-disable-next-line max-len
                        className={`${fontColorPicker(bedroomFontColor)}`}
                        style={{ color: options.bedroomFontColor && options.bedroomFontColor.indexOf("rgba") > -1 ? options.bedroomFontColor : undefined }}
                    >
                        {`${accoTypeNrOfBedrooms || unitTypeNrOfBedrooms} ${
                            accoTypeNrOfBedrooms! > 1 || unitTypeNrOfBedrooms! > 1
                                ? getI18nLocaleString(namespaceList.pluginSearchResultsPanel, "bedrooms", currentLocale, site)
                                : getI18nLocaleString(namespaceList.dynamicBedroom, "bedroom", currentLocale, site)
                        }`}
                    </span>
                }
                {options.iconConfiguration?.iconRight && <Icon iconConfiguration={options.iconConfiguration} />}
            </div>
        </div>
    ) : null;
};
